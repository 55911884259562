.navSmall {
    height: 100%
}

.hamburger-icon {
    height: 3rem;
}


.hamburger-icon:hover {
    cursor: pointer;
    scale: 102%;
    filter: brightness(120%)
}

.HamburgerMenu {
    display: flex;
    background-color: rgb(33, 37, 41);
    padding: 0.25rem;
    padding-left: 1rem;
    z-index: 9;
    height: 100vh;
    transition: 0.2s
}

.menu-open {
    position: absolute;
    top: 0px;
    right: 0px;
    box-shadow:0 0 0 10000px rgba(0,0,0,.50);
}

.menu-closed {
    position: absolute;
    top: 5px;
    right: -70vw;
}

.list-group {
    list-style: none;
}

.accordion-item:hover {
    cursor: pointer;
}

.accordion-body > .dropdown-menu {
    width: 100%;
    border: 0;
}

.hb-dropdown > .dropdown-item {
    border-radius: 5%;
}

.hb-dropdown > .dropdown-item:hover {
    background-color: rgb(64, 66, 69) !important;
}

.text-hb {
    color: rgba(255, 255, 255, 0.55) !important;
    /* color: rgb(255, 255, 255) !important; */
}

/* ------------------ Regular Web View - >991px ------------------ */

@media (min-width: 992px) {
    .navFull {
        display: flex !important;
    }

    .navSmall {
        display: none !important;
    }

    .HamburgerMenu {
        display: none !important;
    }

}

/* ------------------ Mobile View Lg <991px && >768px ------------------ */


@media (max-width: 991px) {
    .navFull {
        display: none !important;
    }

    .navSmall {
        display: flex !important;
    }

    .HamburgerMenu {
        font-size: 1.75rem !important;
        width: 40vw !important;
    }

    .HamburgerMenu > .nav-item {
        margin: 0.1rem 0;
        padding: 0.5rem 0;
        width: 100%;
        display: flex;
        justify-content: start;
    }

    .nav-item.dropdown-toggle {
        align-self: start;
    }

    .dropdown-center > .nav-link {
        padding: 0;
    }

    #cartText {
        font-size: 1.75rem !important;
    }

    .hb-link-item {
        width: 100% !important;
        padding: 10px 20px !important;
    }

    .hb-link-item:hover {
        background-color:rgb(64, 66, 69);
    }

    .hb-link-text {
        padding-left: 0.3rem;
    }

    .btn-close-white:hover {
        scale: 102%;
    }
}

/* ------------------ Mobile View Md <767px && >576px ------------------ */
@media (max-width: 767px) {
    .HamburgerMenu {
        width: 50vw !important;
    }
}

/* ------------------ Mobile View Md <767px && >576px ------------------ */
@media (max-width: 576px) {
    .HamburgerMenu {
        width: 70vw !important;
        font-size: 2rem !important;
    }

    .hb-link-item {
        padding: 1rem 20px !important;
    }
}
