
/* #card-full {
    height: 15rem;
} */
.ProductCard {
    height: 14rem;
}

.ProductCardInner {
    max-height: 100%;
}

.ProductCardInner:hover {
    scale: 101%;
    -webkit-box-shadow: 0 0 2px #000000;
    box-shadow: 0 0 2px #000000;

    .card-upper{
        .card-product-name {
            color: #008bdc;
        }
    }
}

.card-picture-wrapper {
    max-width: 100%;
    max-height: 100%;
}

.card-picture {
    max-width: 100%;
    max-height: 12rem;
}
