/* --------- Global --------- */
.App {
    text-align: center;
    background-color: #ffffff;
}

a {
    text-decoration: none !important;
}

/* --------- Navbar --------- */
#searchbarinput {
    border-radius: 0.375rem 0 0 0.375rem !important;
    border: 0 !important;
}
#searchbaricon {
    width: 1.5rem;
}
#searchbarbutton {
    border-radius: 0 0.375rem 0.375rem 0 !important;
    border: 0 !important;
    background-color: #2fb4ff;
}
.navbar-brand {
    font-size: 1.5rem !important;
}
#cartText {
    font-size: 0.75rem;
    align-self: flex-end;
}

#navbarMenu {
    display: flex;
    flex-direction: row;
    align-items: center !important;
}

#category-btn:hover {
    cursor: pointer;
}

@media (max-width: 993px) {
    #navbarMenu {
        display: flex;
        flex-direction: column-reverse;
        align-items: center !important;
    }
    #navbarMenuLinks {
        display: flex;
        flex-direction: column;
        align-items: center !important;
        margin-bottom: 1rem;
    }
}

.dropdown-center:hover .dropdown-menu {
    display: block;
    position: absolute;
    /* right: -30%; */
    margin-top: 0; /* remove the gap so it doesn't close */
}

.dropdown-center:hover .dropdown-menu {
    display: block;
    position: absolute;
    right: -30%;
    margin-top: 0; /* remove the gap so it doesn't close */
}

/* --------- Footer --------- */
.footer-link {
    font-size: 1.2rem !important;
}

.footer-link:hover {
    /* scale: 105%; */

    text-decoration: underline !important;
    text-underline-offset: 0.25rem !important;
}

.footer-copyright {
    font-size: 0.9rem !important;
}

/* --------- Product Cards --------- */
.card {
    /* gap: 25px !important; */
    max-height: 60vh !important;
    /* background-color: #efefef !important; */
}

.card:hover {
    scale: 103%;
    h5 {
        color: green;
    }
}

.card-upper {
    height: 80% !important;
    flex-shrink: 0;
    border-radius: 6px 6px 0 0;
}

.card-lower {
    height: 15% !important;
    /* border: 1px solid rgb(203, 203, 203);
    border-top: none;
    border-radius: 0 0 6px 6px; */
}

.card-img-top {
    max-height: 100% !important;
    width: auto !important;
    max-width: 100%;
}

.card-upper {
    height: 60% !important;
}

.card-lower {
    height: 30% !important;
}

.card-text {
    text-overflow: ellipsis;
    overflow: hidden;
}

/* --------- Carousel Buttons --------- */
.control-prev {
    position: absolute !important;
    top: 32%;
    left: 0%;
    background-color: rgb(250, 250, 250);
    border: 1px gray solid;
    border-left: 0px;
    border-radius: 0 0.3rem 0.3rem 0;
    box-shadow: 1px 1px 1px;
    /* padding-top: 9px; */
    padding: 1rem 0.5rem;
}

.control-next {
    position: absolute;
    top: 32%;
    right: 0%;
    background-color: rgb(250, 250, 250);
    border: 1px gray solid;
    border-left: 0px;
    border-radius: 0.3rem 0 0 0.3rem;
    box-shadow: -1px 1px 1px;
    /* padding-top: 9px; */
    padding: 1rem 0.5rem;
}

.control-prev:hover {
    background-color: rgb(255, 255, 255);
    .control-prev-icon {
        color: black !important;
    }
}
.control-next:hover {
    background-color: rgb(255, 255, 255);
    .control-next-icon {
        color: black !important;
    }
}

.control-prev-icon {
    font-size: 2rem;
    color: rgb(83, 83, 83);
}

.control-next-icon {
    font-size: 2rem;
    color: rgb(83, 83, 83);
}

/* Replace btn-primary color */
/* .btn-primary {
    color: black !important;
} */
.btn-primary:hover {
    background-color: #00a0fd !important;
    scale: 102%;
}



/* form-label */

.form-label {
    font-size: 1.4rem;
}

.form-control {
    font-size: 1.2rem !important;
}

textarea.form-control {
    font-size: 1rem !important;
}

.admin-btn:hover {
    scale: 103%;
}

.dd-item-hover:hover {
    cursor: pointer;
}

.float-bg {
    font-size: 1.1rem !important;
}

.float-bg::after {
    background-color: rgba(255, 255, 255, 0) !important;
}

/* Product View Page */

#product-view-img {
    max-height: 35rem;
    min-height: 25rem;
}

/* Product Card */

.card-product-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ap-cat-card:hover {
    scale: 101%;
    cursor: pointer;
}

.ap-cat-card > .ap-cat-card-inner > .ap-cat-card-title:hover {
    /* text-decoration: underline; */
}


/* New Order Bar */
.NewOrderBar {
    height: 2rem;
    position: relative;
    font-weight: 400;
}

.order-bar-text {
    position: absolute;
}


/* Account Panels */
.acc-panel-btn {
    min-width: 104px;
}



.qty-btn-dropdown {
    min-width: 5rem !important;
}

.qty-btn:hover {
    cursor: pointer;
    /* background-color:rgb(210, 210, 210) !important */
}

.qty-btn-item {
    font-size: 1.1rem;
}

.qty-btn-item:hover {
    cursor: pointer;
}

.cart-delete-btn {
    border: 1px solid rgb(255, 255, 255) !important;
}

.cart-delete-btn:hover {
    border: 1px solid rgb(220, 53, 69) !important;
}
