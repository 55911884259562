html, body {
    height: 100%;
    max-width: 100vw !important;
    overflow-x: hidden;
    /* background-color: rgb(225, 225, 225); */
}

#root {
    height: 100%
}
